/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.manager--content[data-v-e0cb847c] {
  padding: 1rem;
  display: flex;
}
.health-check[data-v-e0cb847c]  .selection-holder {
  width: 40rem !important;
}
.alert[data-v-e0cb847c] {
  text-align: left;
  box-sizing: border-box;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border-left: 3px solid transparent;
  margin-top: 1rem;
}
.success[data-v-e0cb847c] {
  border-left-color: #c3e6cb;
}
.success svg[data-v-e0cb847c] {
  color: #155724;
}
.warning[data-v-e0cb847c] {
  border-left-color: #ffeeba;
}
.warning svg[data-v-e0cb847c] {
  color: #856404;
}
.error[data-v-e0cb847c] {
  border-left-color: #f5c6cb;
}
.error svg[data-v-e0cb847c] {
  color: #721c24;
}
.alert__status-icon[data-v-e0cb847c] {
  padding-right: 1.25rem;
  font-size: 2rem;
}
.manager-card-wrapper[data-v-e0cb847c] {
  flex-basis: 60%;
}
.manager-card[data-v-e0cb847c] {
  margin-bottom: 1rem;
  background-color: #f3f3f3;
}
.manager-card__header[data-v-e0cb847c] {
  margin-left: 1rem;
  margin-right: 1rem;
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter-wrapper[data-v-e0cb847c] {
  background: white;
  flex-basis: 40%;
  margin-left: 1rem;
}
.form-control[data-v-e0cb847c] {
  margin-bottom: 1rem;
}
.theme-icon[data-v-e0cb847c] {
  display: inline-flex;
}
section[data-v-e0cb847c] {
  margin-bottom: 2rem;
}
section h1[data-v-e0cb847c] {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  text-decoration: underline;
}
section p[data-v-e0cb847c] {
  margin-bottom: 1rem;
}
section .action[data-v-e0cb847c] {
  margin-bottom: 1rem;
}
[data-v-e0cb847c] .no-wrap {
  white-space: nowrap;
}
.no-manager-data[data-v-e0cb847c] {
  background-color: #f3f3f3;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1rem;
  border-radius: 0;
}
.centerY[data-v-e0cb847c] {
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
}
.bg-grey[data-v-e0cb847c] {
  background: #f3f3f3;
}
.h-100[data-v-e0cb847c] {
  height: 100%;
}
.mt-3[data-v-e0cb847c] {
  margin-top: 2rem;
}