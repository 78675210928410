/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.benchmarks {
  font-size: 1.2rem;
  margin-top: 1rem;
  position: relative;
}
.benchmarks .warning-icon.warning {
  color: #ffae00 !important;
}
.benchmarks .warning-icon.error {
  color: #d2222d !important;
}
.benchmarks button {
  background-color: #a3a3a3;
  border-radius: 10px;
  color: #f3f3f3;
}
.benchmarks button .button-text {
  font-size: 1.2rem;
  font-weight: 600;
}
.benchmarks button .icon {
  transition: transform 0.3s;
}
.benchmarks button .icon.flipped {
  transform: rotate(180deg);
}
.benchmarks .messages-wrapper {
  display: none;
  margin-top: 1rem;
}
.benchmarks .messages-wrapper.expanded {
  display: block;
}
.benchmarks .messages-wrapper .message {
  margin-bottom: 1rem;
  position: relative;
}
.benchmarks .messages-wrapper .message hr {
  margin: 1rem 0;
}