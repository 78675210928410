/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.health-check--bg[data-v-62975bde] {
  background-color: #f3f3f3;
}
.th-name[data-v-62975bde] {
  min-width: 150px;
  text-align: left;
}
.position-sticky[data-v-62975bde] {
  background-color: #f3f3f3;
  position: sticky;
  top: 0;
  z-index: 1;
}
.font-weight-bold[data-v-62975bde] {
  font-weight: bold;
}
.table-striped .tbody.tr[data-v-62975bde]:nth-of-type(even) {
  background: #ebebeb;
}
.data[data-v-62975bde] {
  font-size: 1.2rem;
  position: relative;
}
.data .warning-icon[data-v-62975bde] {
  padding-right: 0.25rem;
}
.data .warning-icon.warning[data-v-62975bde] {
  color: #ffae00;
}
.data .warning-icon.error[data-v-62975bde] {
  color: #d2222d;
}
.thead[data-v-62975bde] {
  font-size: 1.4rem;
}
.w-100px[data-v-62975bde] {
  width: 100px;
}